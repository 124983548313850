<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <b-overlay>
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-form-group
              label="Select Startup"
              label-class="h5"
            >
              <v-select
                id="selectStartup"
                v-model="selectStartup"
                :close-on-select="true"
                :get-option-label="option => option.org"
                :options="programs"
                placeholder="Choose Startup"
                @input="$router.replace(`/startup-portal/competitions/enter/${$route.params.id}/${selectStartup.current_application_form_id}/${selectStartup.id}`)"
              />
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-overlay>
</template>

<script>
import {
  BCard, BCol, BFormGroup, BOverlay, BRow,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      selectStartup: Number.parseInt(this.$route.params.aid, 10),
      programs: [],
      applications: [],
    }
  },
  computed: {
    ...mapGetters({
      selectedOrg: 'user/getSelectedOrgDetails',
    }),
  },
  apollo: {
    programs: {
      query() {
        return gql`
        {
          programs_applicantstable(where: {organization_id_id: {_eq: ${this.selectedOrg.orgId}},program_id: {_eq: ${this.$route.params.id}}}) {
            current_application_form_id
            organization_id_id
            id
            users_organizationtable {
               title
            }
          }
        }`
      },
      update(data) {
        if (data.programs_applicantstable.length === 1) {
          this.$router.replace(`/startup-portal/competitions/enter/${this.$route.params.id}/${data.programs_applicantstable[0].current_application_form_id}/${data.programs_applicantstable[0].id}`)
        }
        return data.programs_applicantstable
      },
    },

  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
